<template>
  <div class="specialPosition">
    <div class="header">
        <ds-header title="专场职位管理" style="width: 100%"></ds-header>
        <a-button type="primary" @click="() => this.$router.go(-1)">返回</a-button>
    </div>
    <a-form layout="inline">
      <a-form-item label="职位名称：">
        <a-input v-model.trim="searchForm.jobName" placeholder="请输入职位名称以搜索" class="inputClass" />
      </a-form-item>
      <a-form-item label="企业名称：">
        <a-input v-model.trim="searchForm.enterpriseName" placeholder="输入企业名称以搜索" style="width: 200px; margin-left: 14px" />
      </a-form-item>
      <a-form-item label="所属模块：">
        <a-select
          v-model.trim="searchForm.moduleId"
          class="inputClass"
          placeholder="请选择所属模块"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option value=""> 全部模块 </a-select-option>
          <a-select-option v-for="option in moduleList" :key="option.moduleId" :value="option.moduleId" >{{
            option.moduleName
          }}</a-select-option>
        </a-select>
      </a-form-item>
      <span class="btnClass">
        <a-button type="primary" @click="search" style="margin-top: 3px"> <a-icon type="search" />查询 </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm">重置</a-button>
      </span>
    </a-form>
    <div
      style="padding-top: 15px"
    >
<!--      v-if="$checkButtonPermissions($buttonPermissionsConfig.special.spPosition.add)"-->
      <a-button type="primary" @click="bindPosition" style="margin-left: 15px">绑定职位</a-button>
    </div>
    <div style="padding-top: 15px">
      <a-table
        :rowKey="(record, index) => index"
        :columns="table.columns"
        :data-source="table.dataInfo"
        :pagination="table.pagination.total ? table.pagination : false"
        @change="pageChange"
        bordered
        :scroll="{ x: 1500 }"
      >
        <span slot="action" slot-scope="text, record" class="action">
          <a
            href="javascript:;"
            @click="toDetail(record)"
          >
            查看职位信息
          </a>
          <a
            href="javascript:;"
            style="color: #f5222d" 
            @click="deleteById(record)"
          >
<!--            v-if="$checkButtonPermissions($buttonPermissionsConfig.special.spPosition.del)"-->
            删除
          </a>
        </span>
      </a-table>
    </div>
    <ConfirmBoxModule ref="confirmBoxModule"></ConfirmBoxModule>
    <AddModulePosition
            v-if="showAddModulePosition"
            :show-add-module-position.sync="showAddModulePosition"
            :moduleList="moduleList"
            @refreshPositionList="getSpecialPositionList"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import ConfirmBoxModule from "@/components/confirmBoxModule";
import ConfirmConfig from "@/utils/confirmConfig";
import DSTable from "@/components/DSComponents/DSTable";
import debounce from "lodash/debounce";
import * as api from "../../../../api/socialRecruitingServicesManagement";
import moment from "moment";
import AddModulePosition from './components/addPosition';

export default {
  name: "specialPosition",
  components: {
    ConfirmBoxModule,
    DSTable,
    AddModulePosition,
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.load.loading,
    }),
  },
  data() {
    return {
      searchForm: {
        jobName: "",
        enterpriseName: "",
        moduleId: "",
        specialId: this.$route.query.id,
      },
      // 模块列表
      moduleList: [],
      // 绑定职位
      showAddModulePosition: false,
      // 列表选中的职位
      listSelectedPosition: "111111",
      table: {
        dataInfo: [],
        columns: [
          {
            title: "职位名称",
            dataIndex: "jobName",
            key: "jobName",
          },
          {
            title: "企业名称",
            dataIndex: "enterpriseName",
            key: "enterpriseName",
          },
          {
            title: "就职园区",
            dataIndex: "projectName",
            key: "projectName",
          },
          {
            title: "所属模块",
            dataIndex: "moduleName",
            key: "moduleName",
          },
          {
            title: "创建人",
            dataIndex: "createName",
            key: "createName",
          },
          {
            title: "创建时间",
            dataIndex: "gmtCreate",
            key: "gmtCreate",
          },
          {
            title: "职位状态",
            dataIndex: "publishStatus",
            key: "publishStatus",
            customRender(text) {
              return text ? "未发布" : "已发布";
            },
          },
          {
            title: "操作",
            key: "spaction",
            width: "280px",
            scopedSlots: { customRender: "action" },
          },
        ],
        pagination: {
          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          total: 0,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          },
        },
      }
    }
  },
  mounted() {
    this.getSpecialPositionList();
    this.getModuleList();
  },
  methods: {
    // 删除
    async deleteById(record) {
      const confirmData = {
        title: "提示",
        content: "是否确认删除职位？",
        type: ConfirmConfig.CONFIRM_TYPE_WARNING,
      };
      await this.$refs.confirmBoxModule.show(confirmData);
      await this.specialPositionDelete(record.positionId);
    },
    // 删除职位
    async specialPositionDelete(id) {
      let data = {};
      data.id = id;
      const res = await api.specialPositionDelete(id);
      if (res.code === "200") {
        this.$message.success("删除成功");
        await this.getSpecialPositionList();
      } else {
        this.$message.error(res.msg);
      }
    },
    // 获取列表
    async getSpecialPositionList() {
      const data = {
        page: this.table.pagination.current,
        size: this.table.pagination.pageSize,
        ...this.searchForm,
      };
      const res = await api.getSpecialPositionList(data);
      res.data.list.map((item) => {
        if (item.gmtCreate) {
          item.gmtCreate = moment(item.gmtCreate).format("YYYY-MM-DD HH:mm:ss");
        }
        if (item.gmtModified) {
          item.gmtModified = moment(item.gmtModified).format("YYYY-MM-DD HH:mm:ss");
        }
      });
      this.table.dataInfo = res.data.list;
      this.table.pagination.total = res.data.total;
    },
    // 获取专题模块列表
    async getModuleList() {
      const res = await api.getSpecialModuleList(this.$route.query.id);
      this.moduleList = res.data;
    },
    //查询
    search() {
      this.table.pagination.current = 1;
      this.getSpecialPositionList();
    },
    //重置
    resetSearchForm() {
      this.table.pagination.current = 1;
      this.table.pagination.pageSize = 10;
      this.searchForm.jobName = "";
      this.searchForm.enterpriseName = "";
      this.searchForm.moduleId = "";
      this.getSpecialPositionList();
    },
    // 绑定职位
    bindPosition() {
      this.showAddModulePosition = true;
    },
    // 职位详情
    toDetail(record) {
      this.$router.push({
        path: "/socialRecruitingServicesManagement/recruitmentInformationManagement/detail",
        query: {
          id: record.recruitId,
        },
      });
    },
    // 关闭蒙层的操作(取消校验)
    cancel() {
      this.$refs.ruleForm.resetFields();
    },
    //分页操作
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.getSpecialPositionList();
    },
  },
  created() {
  }
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
}
.action a {
  color: #1890ff;
  margin-right: 15px;
}
.action a:hover {
  color: #40a9ff;
}
.field {
  font-size: 12px;
  color: #333;
  display: inline-block;
  height: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 80px;
}
.inputClass {
  width: 200px;
}
.inputColor {
  color: #bdbdbd !important;
}
</style>
